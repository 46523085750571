import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { every, isEqual } from 'lodash/index';
import { Observable } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';

// A good ref: https://material.io/design/layout/responsive-layout-grid.html#breakpoints
const PHONE_DEVICE_MAX_WIDTH = 767;
// Should match the SCSS variable $mobile-max-width
const SMALL_DEVICE_MAX_WIDTH = 767;
const LARGE_DEVICE_MIN_WIDTH = 767;
const TABLET_DEVICE_MIN_WIDTH = 767;
const TABLET_DEVICE_MAX_WIDTH = 1280;
const LAPTOP_DEVICE_MIN_WIDTH = 1280;
const LAPTOP_DEVICE_MAX_WIDTH = 1440;
const DESKTOP_DEVICE_MIN_WIDTH = 1440;
const DESKTOP_DEVICE_MAX_WIDTH = 1920;
const HIGH_DEFINITION_DEVICE_MIN_WIDTH = 1920;
const EXPANDED_LEFT_MENU_DEVICE_MIN_WIDTH = 1440;
const BREAKPOINT_LARGE = 992; // equivalent to $breakpoint-lg

@Injectable({
  providedIn: 'root',
})
export class QpResponsiveService {
  public constructor(private readonly _breakpointObserver: BreakpointObserver) {}

  /**
   * @description
   * Check if the viewport has a max width of 767px
   * Basically, any phone
   * @returns {Observable<boolean>} true when the viewport has a max width of 767px
   * @deprecated prefer to use the mobile one instead
   */
  public isSmallDevice$(): Observable<boolean> {
    return this._matches$(`(max-width: ${SMALL_DEVICE_MAX_WIDTH}px)`);
  }

  /**
   * @description
   * Check if the viewport is more or less than 1440px
   * @returns {Observable<boolean>} returns true if the viewport is less than 1440px
   */
  public isMainNavBarCollapsed$(): Observable<boolean> {
    return this._matches$(`(max-width: ${EXPANDED_LEFT_MENU_DEVICE_MIN_WIDTH}px)`);
  }

  /**
   * @description
   * Check if the viewport has a min width of 767px
   * Basically, anything larger than a phone or a small tablet
   * @returns {Observable<boolean>} true when the viewport has a min width of 767px
   * @deprecated 767 is too small to be considered as a large device
   */
  public isLargeDevice$(): Observable<boolean> {
    return this._matches$(`(min-width: ${LARGE_DEVICE_MIN_WIDTH}px)`);
  }

  /**
   * @description
   * Check if the viewport is smaller than 992px
   * Any phone and small tablets
   * @returns {boolean} true when the viewport is smaller than 992px
   */
  public isSmallerThanBreakpointLg(): boolean {
    return this._breakpointObserver.isMatched(`(max-width: ${BREAKPOINT_LARGE}px)`);
  }

  /**
   * @description
   * Check if the viewport has a max width of 767px
   * Basically, any phone
   * @returns {boolean} true when the viewport has a max width of 767px
   */
  public isMobile(): boolean {
    return this._breakpointObserver.isMatched(`(max-width: ${PHONE_DEVICE_MAX_WIDTH}px)`);
  }

  /**
   * @description
   * Check if the viewport has a max width of 767px
   * Basically, any phone
   * @returns {Observable<boolean>} true when the viewport has a max width of 767px
   */
  public isMobile$(): Observable<boolean> {
    return this._matches$(`(max-width: ${PHONE_DEVICE_MAX_WIDTH}px)`);
  }

  /**
   * @description
   * Check if the viewport is between 767px and 1280px
   * Basically, any tablet
   * @returns {boolean} true when the viewport is between 767px and 1280px
   */
  public isTablet(): boolean {
    return this._breakpointObserver.isMatched([`(min-width: ${TABLET_DEVICE_MIN_WIDTH}px)`, `(max-width: ${TABLET_DEVICE_MAX_WIDTH}px)`]);
  }

  /**
   * @description
   * Check if the viewport is between 767px and 1280px
   * Basically, any tablet
   * @param {Readonly<boolean>} orGreater Also match if the current view is a tablet or greater than a tablet
   * @returns {Observable<boolean>} true when the viewport is between 767px and 1280px
   */
  public isTablet$(orGreater: Readonly<boolean> = false): Observable<boolean> {
    if (orGreater) {
      return this._matches$(`(min-width: ${TABLET_DEVICE_MIN_WIDTH}px)`);
    }

    return this._matches$([`(min-width: ${TABLET_DEVICE_MIN_WIDTH}px)`, `(max-width: ${TABLET_DEVICE_MAX_WIDTH}px)`]);
  }

  /**
   * @description
   * Check if the viewport is between 1280px and 1440px
   * Basically, any small laptop
   * @returns {boolean} true when the viewport is between 1280px and 1440px
   */
  public isLaptop(): boolean {
    return this._breakpointObserver.isMatched([`(min-width: ${LAPTOP_DEVICE_MIN_WIDTH}px)`, `(max-width: ${LAPTOP_DEVICE_MAX_WIDTH}px)`]);
  }

  /**
   * @description
   * Check if the viewport is between 1280px and 1440px
   * Basically, any small laptop
   * @param {Readonly<boolean>} orGreater Also match if the current view is a laptop or greater than a laptop
   * @returns {Observable<boolean>} true when the viewport is between 1280px and 1440px
   */
  public isLaptop$(orGreater: Readonly<boolean> = false): Observable<boolean> {
    if (orGreater) {
      return this._matches$(`(min-width: ${LAPTOP_DEVICE_MIN_WIDTH}px)`);
    }

    return this._matches$([`(min-width: ${LAPTOP_DEVICE_MIN_WIDTH}px)`, `(max-width: ${LAPTOP_DEVICE_MAX_WIDTH}px)`]);
  }

  /**
   * @description
   * Check if the viewport is between 1440px and 1920px
   * Basically, any large laptop
   * @returns {boolean} true when the viewport is between 1440px and 1920px
   */
  public isDesktop(): boolean {
    return this._breakpointObserver.isMatched([`(min-width: ${DESKTOP_DEVICE_MIN_WIDTH}px)`, `(max-width: ${DESKTOP_DEVICE_MAX_WIDTH}px)`]);
  }

  /**
   * @description
   * Check if the viewport is between 1440px and 1920px
   * Basically, any large laptop
   * @returns {Observable<boolean>} true when the viewport is between 1440px and 1920px
   */
  public isDesktop$(): Observable<boolean> {
    return this._matches$([`(min-width: ${DESKTOP_DEVICE_MIN_WIDTH}px)`, `(max-width: ${DESKTOP_DEVICE_MAX_WIDTH}px)`]);
  }

  /**
   * @description
   * Check if the viewport is greater than 1920px
   * Basically, any large laptop in HD, 2K, 4K
   * @returns {boolean} true when the viewport is greater than 1920px
   */
  public isHighDefinition(): boolean {
    return this._breakpointObserver.isMatched(`(min-width: ${HIGH_DEFINITION_DEVICE_MIN_WIDTH}px)`);
  }

  /**
   * @description
   * Check if the viewport is greater than 1920px
   * Basically, any large laptop in HD, 2K, 4K
   * @returns {Observable<boolean>} true when the viewport is greater than 1920px
   */
  public isHighDefinition$(): Observable<boolean> {
    return this._matches$(`(min-width: ${HIGH_DEFINITION_DEVICE_MIN_WIDTH}px)`);
  }

  private _matches$(breakpoint: Readonly<string> | ReadonlyArray<string>): Observable<boolean> {
    return this._breakpointObserver.observe(breakpoint).pipe(
      distinctUntilChanged((prev: BreakpointState, curr: BreakpointState): boolean => {
        return isEqual(prev.breakpoints, curr.breakpoints);
      }),
      map((breakpointState: Readonly<BreakpointState>): boolean => {
        // We need to check that every single breakpoint match the requirements
        return every(breakpointState.breakpoints, (isMatched: Readonly<boolean>): boolean => {
          return isMatched;
        });
      })
    );
  }
}
